<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <div
      class="ml-2 font-weight-medium text-h5 black--text pt-4 mb-4 d-flex align-center"
    >
      Alta de Usuarios
    </div>
    <v-row>
      <v-col
        cols="24"
        md="12"
      >
        <v-card
          elevation="2"
        >
          <!-- Tabla de Pedidos -->
          <v-data-table
            :headers="headers_usuarios"
            :items="items"
            sort-by="cuit"
            :items-per-page="20"
            class="elevation-1"
          >
            <template #top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Administrar Usuarios</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
                <v-spacer />
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Agregar Usuario
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Agregar Usuario</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.nombre"
                              label="Nombre"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.apellido"
                              label="Apellido"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.cuit"
                              label="CUIT"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          />
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          />
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.telefono"
                              label="Telefono"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.email"
                              label="Email"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.grupo"
                              label="Grupo"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          />
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          />
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="dialogDelete"
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title class="text-h5">
                      Está seguro que desea borrar a éste usuario
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDelete"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                      >
                        OK
                      </v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template #item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template #no-data>
              <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  export default {
    name: 'DashboardView',
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        actions_usuarios: [
          {
            icon: 'mdi-account-edit',
            title: 'Editar',
            color: 'sendBtn',
            emit: 'editItem',
          },
          {
            icon: 'mdi-delete',
            title: 'Eliminar',
            color: 'sendBtn',
            emit: 'deleteItem',
          },
        ],
        editedIndex: -1,
        editedItem: {
          nombre: '',
          apellido: '',
          cuit: '',
          telefono: '',
          grupo: '',
          email: '',
        },
        defaultItem: {
          nombre: '',
          apellido: '',
          cuit: '',
          telefono: '',
          grupo: '',
          email: '',
        },
      }
    },
    computed: {
      items: get('altaUsuarios/items'),
      headers_usuarios: get('altaUsuarios/headers'),
      loading_usuarios: get('altaUsuarios/loading'),
      count_usuarios: get('altaUsuarios/count'),
    },
    created () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.getUsuarios()
      },

      editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.items.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem)
          const item = this.items[this.editedIndex]
          this.updateUser({ item })
        } else {
          this.items.push(this.editedItem)
          const item = this.editedItem
          this.updateUser({ item })
        }
        this.close()
      },
      getUsuarios: call('altaUsuarios/getData'),
      updateUser: call('altaUsuarios/updateUser'),
      createUser: call('altaUsuarios/createUser'),
    },
  }
</script>
